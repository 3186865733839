import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { Web3Service } from '../../shared/web3-service';
import { AlertService } from '../shared-dlg.module';
import networks from '../networks.data';

@Component({
  selector: 'dlg-switch-network',
  templateUrl: './dlg-switch-network.component.html',
  styleUrls: ['./dlg-switch-network.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DlgSwitchNetworkComponent implements OnInit {
  constructor(
    public web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private _alertSrv: AlertService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  async selectNetworkClick(chainId: number): Promise<boolean> {
    if (this.web3Service.chainIdNumber == chainId) {
      //The same network
      this._dialog.closeAll();
      return false;
    }

    var toNetwork = networks.find((n) => n.chainId == chainId);

    const provider = window.ethereum;
    //If exist metamask and connected account
    //TODO: get account from web3
    if (provider && this.userSessionProvider.linkedWallet) {
      if (toNetwork.networkParams) {
        try {
          // @ts-ignore
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [toNetwork.networkParams],
          });
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      } else {
        this._alertSrv.show(
          `Select ${toNetwork.name} Network in your wallet.`,
          'error'
        );
        return false;
      }
    } else {
      this.userSessionProvider.setChainId(toNetwork.chainId);
      location.reload();
      return false;
    }
  }
}
