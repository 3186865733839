<main class="dashboard-main" [ngClass]="{'loading-page': waiting}">
  <form #createDealForm="ngForm" (ngSubmit)="toStep2()" class="create-deal-form">
    <section class="dashboard-section pt-40">

      <div class="card p-4 mb-40">
        <div class="subtitle-1 text-break mb-4">Currency: {{paymentToken?.address}}</div>

        <div class="row">
          <div class="col-lg-6 mb-4 mb-lg-0">
            <mat-form-field appearance="fill" class="icon-form-field">
              <img src="../../assets/images/currency/{{paymentToken.icon}}"/>
              <mat-label>Currency</mat-label>
              <mat-select disableRipple disableOptionCentering [(ngModel)]="paymentToken" name="paymentToken">
                <mat-option *ngFor="let item of allPaymentTokens" [value]="item">
                  <img src="../../assets/images/currency/{{item.icon}}"/>
                  {{item.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-lg-6 mb-4 mb-lg-0">
            <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field">
              <img src="../../assets/images/currency/{{paymentToken.icon}}"/>
              <mat-label>Token Price ({{paymentToken?.name}})</mat-label>
              <input matInput [(ngModel)]="tokenPrice" [disabled]="step>1" name="tokenPrice" required>
              <mat-hint *ngIf="step==2">1 {{rewardTokenSymbol}} = {{tokenPrice}} {{paymentToken?.name}}</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="card p-4 mb-40">
        <div class="subtitle-1 text-break mb-3">Reward Address: {{rewardTokenSymbol}}</div>

        <mat-form-field appearance="fill" hideRequiredMarker>
          <mat-label>Address of reward Token</mat-label>
          <input matInput
                 [(ngModel)]="rewardAddress"
                 [disabled]="step>1"
                 name="rewardAddress"
                 required
                 autocomplete="off"
          >
        </mat-form-field>
      </div>

      <div class="row gx-4">
        <div class="col-lg-4">
          <div class="card p-4 mb-40">
            <div class="subtitle-1 text-break mb-3">Start Date</div>
            <div class="small-text mb-3" *ngIf="step>1">{{startTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
            <mat-form-field appearance="fill" hideRequiredMarker>
              <mat-label>Start Timestamp</mat-label>
              <input matInput
                     type="number"
                     [(ngModel)]="startTimestamp"
                     [disabled]="step>1"
                     name="startTimestamp"
                     required
              >
            </mat-form-field>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card p-4 mb-40">
            <div class="subtitle-1 text-break mb-3">End Timestamp</div>
            <div class="small-text mb-3" *ngIf="step>1">{{finishTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
            <mat-form-field appearance="fill" hideRequiredMarker>
              <mat-label>Finish Timestamp</mat-label>
              <input matInput
                     type="number"
                     [(ngModel)]="finishTimestamp"
                     [disabled]="step>1"
                     name="finishTimestamp"
                     required
              >
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card p-4 mb-40">
            <div class="subtitle-1 text-break mb-3">Token Distribution</div>
            <div class="small-text mb-3" *ngIf="step>1">{{startClaimTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
            <mat-form-field appearance="fill" hideRequiredMarker>
              <mat-label>Start Claim Timestamp</mat-label>
              <input matInput
                     type="number"
                     [(ngModel)]="startClaimTimestamp"
                     [disabled]="step>1"
                     name="startClaimTimestamp"
                     required
              >
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row gx-4">
        <div class="col-lg-6">
          <div class="card p-4 mb-40">
            <mat-form-field appearance="fill" class="icon-form-field" hideRequiredMarker>
              <img src="../../assets/images/currency/{{paymentToken.icon}}"/>
              <mat-label>Min Raise</mat-label>
              <input matInput
                     type="number"
                     [(ngModel)]="minimumRaise"
                     [disabled]="step>1"
                     name="minimumRaise"
                     required
              >
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card p-4 mb-40">
            <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field">
              <i class="icon icomoon-wallet"></i>
              <mat-label>Max Distributed Token</mat-label>
              <input matInput
                     type="number"
                     [(ngModel)]="maxDistributedTokenAmount"
                     [disabled]="step>1"
                     name="maxDistributedTokenAmount"
                     required
              >
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="card p-4 mb-40 d-flex align-items-center">
        <div class="subtitle-1 me-auto">Allow Refund</div>
        <mat-slide-toggle
          name="allowRefund"
          [(ngModel)]="allowRefund"
          [checked]="allowRefund"
          [disabled]="step>1"
        >
        </mat-slide-toggle>
      </div>
    </section>

    <section class="dashboard-section bg-white py-4 mt-lg-auto">
      <button type="submit"
              class="w-100"
              mat-raised-button
              color="primary"
              *ngIf="step==1"
              [ngClass]="{'wait': waiting}"
              [disabled]="!createDealForm.form.valid || waiting">
        Confirm
      </button>

      <div class="btn-group-justified" *ngIf="step==2">
        <button type="button"
                mat-raised-button
                color="primary"
                [ngClass]="{'btn-loading': waiting}"
                (click)="toStep1()"
                [disabled]="waiting">
          Back
        </button>

        <button type="button"
                *ngIf="!isApprovedRewardToken"
                mat-raised-button
                color="primary"
                [ngClass]="{'btn-loading': waiting}"
                [disabled]="waiting"
                (click)="approveRewardTokenClick()">
          Approve reward token
        </button>

        <button type="button"
                *ngIf="isApprovedRewardToken"
                class="btn-icon btn-icon-left"
                mat-raised-button
                color="primary"
                [ngClass]="{'btn-loading': waiting}"
                (click)="createDealClick()"
                [disabled]="waiting">
          <i class="icon icomoon-plus"></i>
          Create Deal
        </button>
      </div>
    </section>
  </form>
</main>
