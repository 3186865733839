import swal from "sweetalert2"
import BigNumber from "bignumber.js";
declare const window: any;
import { Web3Service } from "./web3-service";
import { injector } from "../main";
import { environment } from "../environments/environment";


//declare var toastr: { error: (arg0: string) => void; warning: (arg0: string) => void; info: (arg0: string) => void; success: (arg0: string) => void; };


export abstract class ComponentBase {
  public isWeb3Disabled: boolean = false;

  public longTimeUpdate: number = 60000;
  public expectedBlockTime: number = 30000;
  public fastTimeUpdate: number = 15000;

  constructor() {
  
  }

  public showError(message: string) {
    //toastr.error(message);
  }

  public showErrorModal(message: string) {
    swal.fire({
      text: message,
      icon: "error"
    });
  }

  public showWarning(message: string) {
    //toastr.warning(message);
  }

  public showWarningModal(message: string) {
    swal.fire({
      text: message,
      icon: 'warning'
    });
  }

  public showInfo(message: string) {
    //toastr.info(message);
  }

  public showInfoModal(message: string) {
    swal.fire({
      text: message,
      icon: "info"
    });
  }

  public showSuccess(message: string) {
    //toastr.success(message);
  }

  public showSuccessModal(message: string) {
    swal.fire({
      text: message,
      icon: "success"
    });
  }

  public showInfoHTMLModal(message: string, confirmButtonText: string) {
    swal.fire({
      html: message,
      icon: "info",
      confirmButtonText: confirmButtonText
    });
  }

  public showWarningHTMLModal(message: string, confirmButtonText: string) {
    swal.fire({
      html: message,
      icon: "warning",
      confirmButtonText: confirmButtonText,
      allowEscapeKey: false,
      allowOutsideClick: false
    });
  }

  explorerURL: string = "#";

  showTransactionSumbited(txId: string, isBNBChain: boolean) {
    let subResp = 'Transaction Submitted';
    let closeResp = 'Close';
    let viewResp = 'View on Etherscan';
    if (isBNBChain)
      viewResp = 'View on BscScan';
    var stringHTML = `<p class="trans_submitted">${subResp}</p><a href="${this.explorerURL}${txId}" target="_blank" class="view_etherscan">${viewResp}</a>`;

    this.showInfoHTMLModal(stringHTML, closeResp);
    //translate.get('Transaction Submitted')
    //    .subscribe((subResp: string) => {
    //        translate.get('View on Etherscan')
    //            .subscribe((viewResp: string) => {
    //                translate.get('Close')
    //                    .subscribe((closeResp: string) => {
    //                        var stringHTML = `<p class="trans_submitted">${subResp}</p><a href="https://etherscan.io/tx/${txId}" target="_blank" class="view_etherscan">${viewResp}</a>`;

    //                        this.showInfoHTMLModal(stringHTML, closeResp);
    //                    });
    //            });
    //    });
  }



  toNumberFromWei(input: string, decimals: number) {
    return new BigNumber(input).shiftedBy(-decimals).toNumber()
  }

  //decimalPlaces: number, roundingMode?: BigNumber.RoundingMode
  toNumberFromWeiFixed(input: string, decimals: number, decimalPlaces: number = 2, roundingMode: BigNumber.RoundingMode = 1) {
    return parseFloat(new BigNumber(input).shiftedBy(-decimals).toFixed(decimalPlaces, roundingMode))
  }

  //#region isMobile

  Opera(): boolean {
    return navigator.userAgent.match(/Opera Mini/i) != null;
  }
  Android(): boolean {
    return navigator.userAgent.match(/Android/i) != null;
  }
  iOS(): boolean {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) != null;
  }
  Windows(): boolean {
    return navigator.userAgent.match(/IEMobile/i) != null || navigator.userAgent.match(/WPDesktop/i) != null;
  }

  isMobile(): boolean {
    return (this.Android() || this.iOS() || this.Opera() || this.Windows());
  }
  //#endregion isMobile


  sortByDesc(items: any, prop: string) {
    return items.sort((a: any, b: any) => a[prop] > b[prop] ? -1 : a[prop] === b[prop] ? -1 : 0);
  }

  sortBy(items: any, prop: string) {
    return items.sort((a: any, b: any) => a[prop] > b[prop] ? -1 : a[prop] === b[prop] ? 0 : -1);
  }
}
