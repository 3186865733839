<aside class="sidenav" id="dashboard-nav">
  <div class="sidenav__logo desktop-logo">
    <a href="/"><img src="../../assets/images/logo-full.svg" alt="Bull Starter"></a>
  </div>

  <div class="sidenav__role">Admin</div>

  <nav class="sidenav__nav">
    <ul class="main-navigation nav">
      <li class="nav-item" *ngFor="let menuItem of menuItems">

        <ng-container *ngTemplateOutlet="menuTemplate; context:{ $implicit: menuItem }"></ng-container>

        <ng-template #menuTemplate let-menu>
          <ng-container *ngTemplateOutlet="menu.childItems ? groupItem : singleItem"></ng-container>

          <ng-template #singleItem>

            <a class="nav-link" *ngIf="!menu.isExternal" [ngClass]="{'active': routerPath == menu.routePath}" [routerLink]="menu.routePath">
              <i class="nav-link__icon" [ngClass]="menu.icon"></i>
              {{menu.label}}
            </a>
            <a class="nav-link" *ngIf="menu.isExternal" rel="noreferrer" href="{{menu.routePath}}" target="_blank">
              <i class="nav-link__icon" [ngClass]="menu.icon"></i>
              {{menu.label}}
            </a>
          </ng-template>

          <ng-template #groupItem>
            <button class="nav-link" (click)="toggleSubMenu($event)"
              [ngClass]="{'active': menu.routePath.includes(routerPath) || routerPath == menu.routePath}">
              <i class="nav-link__icon" [ngClass]="menu.icon"></i>
              {{menu.label}}
            </button>
            <ul class="sub-menu" [ngClass]="{'show': routerPath == menu.routePath}">
              <li class="nav-item" *ngFor="let groupInnerItem of menu.childItems">
                <ng-container *ngTemplateOutlet="menuTemplate; context:{ $implicit: groupInnerItem }"></ng-container>
              </li>
            </ul>
          </ng-template>
        </ng-template>

      </li>
    </ul>
  </nav>
</aside>
